<template>
  <base-section
    id="how-to-deploy"
  >
    <base-section-heading
      color="grey lighten-2"
      :title="$t('deployTitle')"
    >
      {{$t('deploySubTitle')}}
    </base-section-heading>

    <v-container>
      <v-row justify="center">
        <v-col
          v-for="card in getCards"
          :key="card.title"
          cols="12"
          md="6"
        >
          <base-info-card v-bind="card" />
        </v-col>

        <v-col
          v-for="btn in getButtons"
          :key="btn.title"
          cols="12"
          md="2"
        >
          <base-btn
            :href="btn.href"
          >
            {{btn.title}}
          </base-btn>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionHowToDeploy',

    computed: {
      getCards () {
        return [
          {
            title: this.$t('deployReason1Title'),
            subtitle: 'Everything Is Ready',
            text: this.$t('deployReason1Content'),
            callout: '01',
          },
          {
            title: this.$t('deployReason2Title'),
            subtitle: 'Start Olap Service',
            text: this.$t('deployReason2Content'),
            callout: '02',
          },
        ]
      },

      getButtons () {
        return [
          {
            title: this.$t('deployMoreDetail'),
            href: '/docs/user-manual.html#5-安装部署',
          },
        ]
      },
    },

    data: () => ({
      cards: [
        {
          title: '准备好HBase环境',
          subtitle: 'Everything Is Ready',
          text: 'OlapDB的部署需要使用HBase服务。在部署OlapDB的组件前，您先要准备好HBase环境。在安装部署其他服务前，您需要将OlapDB提供协处理器的jar文件下载复制到HBase能够访问的位置。',
          callout: '01',
        },
        {
          title: '通过镜像启动服务',
          subtitle: 'Start Olap Service',
          text: 'OlapDB提供的单一Docker镜像中集成了管理服务、构建服务、查询服务和Web管理控制台。你可以通过启动镜像直接启动这些服务。',
          callout: '02',
        },
      ],
    }),
  }
</script>
